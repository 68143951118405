/* eslint-disable multiline-ternary */
import { transformResponse } from '@src/utility/transformResponse'
import Repository, { baseUrl } from './Repositry'
class AuthRepository {
  async login(params) {
    try {
      const response = await Repository.post(`${baseUrl}/accounts/login/`, params)

      if (
        response?.data?.msg === 'subscription expired please connect to administration' ||
        response?.data?.msg ===
          'No organization is assigned to you please connect with your respect organization admin'
      ) {
        throw 'Subscription Expired, Please connect to Administration.'
      } else {
        return transformResponse(response.data)
      }
    } catch (error) {
      console.log('ERRR', error)
      throw error
    }
  }

  async registerLocal(data) {
    try {
      const reponse = await Repository.post(`${baseUrl}/accounts/register/`, data)
      return transformResponse(reponse.data)
    } catch (error) {
      throw error
    }
  }
  async socialLogin(data) {
    try {
      const reponse = await Repository.get(
        `${baseUrl}/auth/${data.provider}/callback?${data.search}`,
        data
      )
      return transformResponse(reponse.data)
    } catch (error) {
      throw error
    }
  }

  async verifyEmail(data) {
    try {
      const response = await Repository.post(`${baseUrl}/user-verify-email`, data)

      if (response.data.status === 0) {
        throw { response: response.data.message }
      }
      return transformResponse(response.data.message)
      // { error: JSON.stringify(error) }));
    } catch (error) {
      throw error
    }

    // return reponse;
  }

  async updateStudentProfile(id, data) {
    console.log('in updateStudentProfile', id, data)
    try {
      const response = await Repository.patch(
        `${baseUrl}/accounts/profiles/student_profile/${id}/`,
        data
      )

      return transformResponse(response.data)
      // { error: JSON.stringify(error) }));
    } catch (error) {
      throw error
    }
  }
  async updateTeacherProfile(id, data) {
    console.log('in updateTeacherProfile', id, data)
    try {
      const response = await Repository.patch(
        `${baseUrl}/accounts/profiles/teacher_profile/${id}/`,
        data
      )
      return transformResponse(response.data)
      // { error: JSON.stringify(error) }));
    } catch (error) {
      throw error
    }
  }
  async updateParentsProfile(id, data) {
    try {
      const response = await Repository.patch(
        `${baseUrl}/accounts/profiles/parent_profile/${id}/`,
        data
      )
      return transformResponse(response.data)
      // { error: JSON.stringify(error) }));
    } catch (error) {
      throw error
    }
  }
  async updateAdminProfile(id, data) {
    try {
      const response = await Repository.patch(
        `${baseUrl}/accounts/profiles/admin_profile/${id}/`,
        data
      )

      return transformResponse(response.data)
      // { error: JSON.stringify(error) }));
    } catch (error) {
      throw error
    }
  }
  async updateOrganization(id, data) {
    try {
      const response = await Repository.put(`${baseUrl}/organizations/orgs/${id}/`, data)

      return transformResponse(response.data)
      // { error: JSON.stringify(error) }));
    } catch (error) {
      throw error
    }
  }
  async getAdminProfile() {
    try {
      const response = await Repository.get(
        `${baseUrl}/accounts/profiles/admin_profile/?limit=1000`
      )
      return transformResponse(
        response?.data?.results
          .filter((i) => !i?.user?.is_superuser)
          .sort((a, b) => new Date(b.created_at) - new Date(a.created_at))
      )
    } catch (error) {
      throw error
    }
  }
  async getOrgAdmin(org) {
    try {
      const response = await Repository.get(
        `${baseUrl}/accounts/profiles/admin_profile/?limit=1000&organizations=${org}`
      )
      return transformResponse(
        response?.data?.results
          .filter((i) => !i?.user?.is_superuser)
          .sort((a, b) => new Date(b.created_at) - new Date(a.created_at))
      )
    } catch (error) {
      throw error
    }
  }

  async deleteUser(id, data) {
    console.log('Delete User', id, data)
    try {
      const response = await Repository.post(`${baseUrl}/accounts/delete-user/${id}/`, data)
      return transformResponse(response.data)
    } catch (error) {
      throw error
    }
  }
  async resetPassword(data) {
    try {
      const response = await Repository.post(`${baseUrl}/accounts/admin_password_reset/`, data)
      return transformResponse(response.data)
    } catch (error) {
      throw error
    }
  }
  async resetUserPassword(data) {
    try {
      const response = await Repository.post(`${baseUrl}/accounts/password_reset/`, data)
      return transformResponse(response.data)
    } catch (error) {
      throw error
    }
  }
  async verifyToken(token) {
    try {
      const response = await Repository.post(`${baseUrl}/accounts/token-verify/`, { token })
      if (response.data.msg === 'User not found') {
        throw response.data
      }
      return transformResponse(response.data)
    } catch (error) {
      console.log(error)
      throw error
    }
  }

  async getUserCount(orgCode) {
    try {
      const response = await Repository.post(
        `${baseUrl}/accounts/liveinfo/`,
        orgCode ? { org_code: orgCode } : {}
      )
      return transformResponse(response.data)
    } catch (error) {
      console.log(error)
      throw error
    }
  }

  async getTeacherSignUpLink(data) {
    try {
      const response = await Repository.post(`${baseUrl}/organizations/link/`, data)
      return transformResponse(response.data)
    } catch (error) {
      console.log(error)
      throw error
    }
  }

  async registerViaLink(data) {
    try {
      const response = await Repository.post(`${baseUrl}/organizations/link-register/`, data)

      if (response.data.msg !== 'user created successfully and verification code sent') {
        throw response.data.msg
      }
      return transformResponse(response.data)
    } catch (error) {
      console.log(error)
      throw error
    }
  }
  async verifyCode(data) {
    try {
      const response = await Repository.post(`${baseUrl}/organizations/link-code/`, data)
      if (response.data.msg === 'Invalid') {
        throw 'Invalid Code'
      }
      const user = response.data.user
      user.profile = response.data.profile
      return { userData: user, token: response.data.token }
    } catch (error) {
      console.log(error)
      throw error
    }
  }
  async resetPasswordCode(data) {
    try {
      const response = await Repository.post(`/accounts/reset_code/`, data)
      if (response.data.msg === 'No User with this Email') {
        throw 'No User with this Email'
      }
      return response.data
    } catch (error) {
      console.log(error)
      throw error
    }
  }
  async verifyResetPasswordCode(data) {
    try {
      const response = await Repository.post(`/accounts/check_code/`, data)
      if (response.data.msg === 'Invalid') {
        throw 'Invalid'
      }
      return response.data.token
    } catch (error) {
      console.log(error)
      throw error
    }
  }

  async getAvatars(params = {}) {
    try {
      const response = await Repository.get(`accounts/profiles/avatar/`, {
        params: {
          ...params
        }
      })
      if (response.data.msg === 'No User with this Email') {
        throw 'No User with this Email'
      }
      return response.data
    } catch (error) {
      console.log(error)
      throw error
    }
  }
}
export default new AuthRepository()
