// ** Redux Imports
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

// ** UseJWT import to get config
import useJwt from '@src/auth/jwt/useJwt'
import { availableRoles } from '@src/constants/siteVariables'
import AuthRepositry from '@src/repositry/AuthRepositry'
import { useSelector } from 'react-redux'
// import AuthRepositry from '~/repositry/AuthRepositry'

const config = useJwt.jwtConfig

const initialUser = () => {
  const item = window.localStorage.getItem('userData')
  //** Parse stored json or if none return initialValue
  return item ? JSON.parse(item) : null
}

export const handleLogin = createAsyncThunk('auth/login', async ({ data }) => {
  const res = await AuthRepositry.login(data)
    .then((res) => {
      return res
    })
    .catch((err) => {
      const customError = {
        name: 'Custom axios error',
        message: err?.response?.data?.detail
      }
      throw customError
    })
  return res
})

export const handleUpdateUserProfile = createAsyncThunk(
  'auth/updateUser-profile',
  async (abc, { getState }) => {
    let res
    try {
      const { data, callback } = abc
      const oldUserdata = getState().auth.userData
      const { profile, role } = oldUserdata

      if (role.name === availableRoles.student.name) {
        res = await AuthRepositry.updateStudentProfile(profile.id, data)
      } else if (role.name === availableRoles.teacher.name) {
        res = await AuthRepositry.updateTeacherProfile(profile.id, data)
      } else if (
        role.name === availableRoles.schoolAdmin.name ||
        role.name === availableRoles.subOrgAdmin.name
      ) {
        res = await AuthRepositry.updateAdminProfile(profile.id, data)
      }
      res.avatar = res.avatar_details
      delete res.avatar_details

      if (callback) {
        callback()
      }
    } catch (error) {
      throw error
    }

    return res
  }
)

export const handleRegisterLocal = createAsyncThunk(
  'auth/register-local',
  async ({ data, callback }) => {
    const res = await AuthRepositry.registerLocal(data)
    if (callback) {
      callback()
    }
    return res
    // return new Promise((res) => setTimeout(res, 500))
  }
)

export const authSlice = createSlice({
  name: 'authentication',
  initialState: {
    loading: false,
    error: null,
    userData: initialUser(),
    userSurvey: {
      personality: '',
      code: ''
    }
  },
  reducers: {
    handleSetAuthError: (state, { payload }) => {
      state.error = payload
    },
    handleSetUserData: (state, { payload }) => {
      state.error = null
      state.loading = false
      state.userData = payload.userData

      state.accessToken = payload.token

      localStorage.setItem('userData', JSON.stringify(state.userData))

      localStorage.setItem(config.storageTokenKeyName, JSON.stringify(payload.token))
    },
    handleSetAuthLoading: (state, { payload }) => {
      state.loading = payload
    },
    handleLogout: (state) => {
      state.userData = null
      state[config.storageTokenKeyName] = null
      state[config.storageRefreshTokenKeyName] = null
      // ** Remove user, accessToken & refreshToken from localStorage
      localStorage.removeItem('userData')
      localStorage.removeItem(config.storageTokenKeyName)
      localStorage.removeItem(config.storageRefreshTokenKeyName)
    },
    handleUserSurvey: (state, { payload }) => {
      state.userSurvey.code = payload?.code
      state.userSurvey.personality = payload?.personality
    }
  },
  extraReducers: {
    [handleLogin.pending]: (state) => {
      state.loading = true
    },
    [handleLogin.rejected]: (state, { error }) => {
      state.error = error.message
      state.loading = false
    },
    [handleLogin.fulfilled]: (state, { payload }) => {
      state.error = null
      state.loading = false
      state.userData = payload.user
      state.userData.profile = payload.profile
      state.accessToken = payload.access

      localStorage.setItem('userData', JSON.stringify(state.userData))

      localStorage.setItem(config.storageTokenKeyName, JSON.stringify(payload.access))
    },

    [handleRegisterLocal.pending]: (state) => {
      state.error = null
      state.loading = true
    },
    [handleRegisterLocal.rejected]: (state) => {
      state.error = 'User with this Username already exist.'
      state.loading = false
    },
    [handleRegisterLocal.fulfilled]: (state, { payload }) => {
      state.error = null
      state.loading = false
      state.userData = payload.user
      state.userData.profile = payload.profile
      state.accessToken = payload.token
      localStorage.setItem('userData', JSON.stringify(state.userData))
      localStorage.setItem(config.storageTokenKeyName, JSON.stringify(payload.token))
    },
    [handleUpdateUserProfile.pending]: (state) => {
      state.error = null
      state.loading = true
    },
    [handleUpdateUserProfile.rejected]: (state) => {
      state.error = 'Some Thing Went Wrong'
      state.loading = false
    },
    [handleUpdateUserProfile.fulfilled]: (state, { payload }) => {
      state.error = null
      state.loading = false
      state.userData.profile = {
        ...payload,
        organizations: state.userData.profile.organizations,
        user: state.userData.profile.user
      }
    }
  }
})

export const {
  handleLogout,
  handleSetAuthError,
  handleSetAuthLoading,
  handleUserSurvey,
  handleSetUserData
} = authSlice.actions

export const useAppUser = () => {
  return useSelector((state) => state.auth.userData)
}

export default authSlice.reducer
