import { log } from '@craco/craco/lib/logger'
import axios from 'axios'
import { baseUrl } from '../constants/siteVariables'

export const customHeaders = () => {
  // let local = localStorage.getItem('persist:Find-a-Plant');
  // let parseData = JSON.parse(local);

  // console.log(parseData);

  return {
    Accept: 'application/json'
  }
}

// setupInterceptorsTo(axiosNew)

const axiosNew = axios.create({
  baseURL: baseUrl,
  headers: customHeaders()
})

// Add a request interceptor
axiosNew.interceptors.request.use(
  function (config) {
    const t = localStorage.getItem('accessToken')

    if (t) {
      const token = JSON.parse(t)
      config.headers.Authorization = `Bearer ${token}`
    }

    // Do something before request is sent
    return config
  },
  function (error) {
    console.log('👩‍🦰🔺1', error)
    // Do something with request error
    return Promise.reject(error)
  }
)

// Add a response interceptor
axiosNew.interceptors.response.use(
  function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    return response
  },
  function (error) {
    if (error.message.includes('401')) {
      // window.handleLogout()
    }
    // ":"Network Error"
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    // return Promise.reject(error)
    return Promise.reject(error)
  }
)

export default axiosNew
export { baseUrl }

export const serializeQuery = (query) => {
  return Object.keys(query)
    .map((key) => `${encodeURIComponent(key)}=${encodeURIComponent(query[key])}`)
    .join('&')
}
