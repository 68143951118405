import { createSlice } from "@reduxjs/toolkit"
import { object } from "prop-types"

export const goalSlice = createSlice({
    name : 'goal',
    initialState: {
        Goal:{
            goal:"",
            task:"",
            help:"",
            remainder:"",
            celebrate:"",
            specific:"",
            measureable:"",
            achievable:"",
            related:"",
            timely:"",
            goal_id:""
        },
        selectedGoal:{
            
        },
        Calander:{
        },
        events:[],
        edittask:[],
        Goalid: [],
        lastindex:[]
    },
    reducers:{
        AddGoal:(state, action) => {
            state.Goal = {...state.Goal, goal: action.payload}
            console.log("Add Goal", action.payload)
        },
        AddTask:(state, action) => {
            state.Goal = {...state.Goal, task: action.payload}
        },
        AddHelp:(state, action) => {
            state.Goal = {...state.Goal, help: action.payload}
        },
        AddRemainder:(state, action) => {
            state.Goal = {...state.Goal, remainder: action.payload}
        },
        AddCelebration:(state, action) => {
            state.Goal = {...state.Goal, celebrate: action.payload}
        },
        AddSpecific:(state, action) => {
            state.Goal = {...state.Goal, specific: action.payload}
        },
        AddMeasureable:(state, action) => {
            state.Goal = {...state.Goal, measureable: action.payload}
        },
        AddAchievable:(state, action) => {
            state.Goal = {...state.Goal, achievable: action.payload}
        },
        AddRelated:(state, action) => {
            state.Goal = {...state.Goal, related: action.payload}
        },
        AddTimely:(state, action) => {
            state.Goal = {...state.Goal, timely: action.payload}
        },
        Goalid: (state, action) => {
            state.Goalid = action.payload
            
        },
        SelectGoal: (state, action) => {
            state.selectedGoal = action.payload
        },
        AddCalander: (state, action) => {
            state.Calander = action.payload
        },
        addCustomEvents: (state, action) => {
            state.events = [...state.events, action.payload]
            // console.log(action.payload)
        },
        addEditTask: (state, action) => {
            state.Goal = action.payload
            
        },
        Lastindex: (state, action) => {
            state.lastindex = action.payload
        }


    }

})

export const {
    AddGoal, 
    AddTask, 
    AddHelp, 
    AddRemainder,
    AddCelebration,
    AddSpecific,
    AddMeasureable,
    AddAchievable,
    AddRelated,
    AddTimely,
    SelectGoal,
    AddCalander,
    addCustomEvents,
    addEditTask,
    Goalid,
    Lastindex
} = goalSlice.actions
export default goalSlice.reducer