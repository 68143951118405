import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

export const dailyCheckIn = createSlice({
  name: 'dailyCheckIn',
  initialState: {
    questions: {},
    lastSubmitted: ''
  },
  reducers: {
    handleAddQuestions: (state, action) => {
      state.lastSubmitted = new Date()
      state.questions = action.payload
    }
  }
})

export const { handleAddQuestions } = dailyCheckIn.actions

export default dailyCheckIn.reducer
