// ** React Imports
import { Suspense, lazy } from 'react'
import ReactDOM from 'react-dom'
import { QueryClient, QueryClientProvider } from 'react-query'
import { ReactQueryDevtools } from 'react-query/devtools'

// ** Redux Imports
import { Provider } from 'react-redux'
import { store, persistor } from './redux/store'

// ** Intl., CASL & ThemeColors Context
import { AbilityContext } from './utility/context/Can'
import { ToastContainer } from 'react-toastify'
import { ThemeContext } from './utility/context/ThemeColors'
import ability from './configs/acl/ability'
import ScrollToTop from '@src/@core/components/scroll-to-top'

// ** Spinner (Splash Screen)
import Spinner from './@core/components/spinner/Fallback-spinner'

// ** Ripple Button
import './@core/components/ripple-button'

// ** PrismJS
import 'prismjs'
import 'prismjs/themes/prism-tomorrow.css'
import 'prismjs/components/prism-jsx.min'

// ** React Perfect Scrollbar
import 'react-perfect-scrollbar/dist/css/styles.css'

// ** React Toastify
import '@styles/react/libs/toastify/toastify.scss'

// ** Core styles
import './@core/assets/fonts/feather/iconfont.css'
import './@core/scss/core.scss'
import './assets/scss/style.scss'
import '@styles/react/libs/flatpickr/flatpickr.scss'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

// ** i18n
import './configs/i18n'

// ** Service Worker
import * as serviceWorker from './serviceWorker'
import { IntlProviderWrapper } from './utility/context/Internationalization'
import { PersistGate } from 'redux-persist/integration/react'

// ** Lazy load app
const LazyApp = lazy(() => import('./App'))

export const queryClient = new QueryClient({
  
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: false
      }
    }
  
})

ReactDOM.render(
  <QueryClientProvider client={queryClient} >
    <Provider store={store}>
      <PersistGate loading={<Spinner />} persistor={persistor}>
        <Suspense fallback={<Spinner />}>
          <AbilityContext.Provider value={ability}>
            <ThemeContext>
              <IntlProviderWrapper>
                <>
                  <LazyApp />
                  <ScrollToTop />
                  <ToastContainer newestOnTop />
                </>
              </IntlProviderWrapper>
            </ThemeContext>
          </AbilityContext.Provider>
        </Suspense>
      </PersistGate>
    </Provider>
    <ReactQueryDevtools initialIsOpen={false} position="bottom-right" />
  </QueryClientProvider>,
  document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register()
