import { createSlice } from '@reduxjs/toolkit'
import moment from 'moment'

export const slice = createSlice({
  name: 'goals',
  initialState: {
    goals: [],
      // {
      //   category: 'Academic',
      //   subcategory: 'Academic Improvement',
      //   task: 'Improve Grades',
      //   title: 'Title Your Goal',
      //   support: 'Who can help you reach your goal?',
      //   celebrate: 'How will you celebrate when you reach your goal ?',
      //   specific: 'What specifically would you like to accomplish ?',
      //   measurable: 'How will you know when you have accomplished your goal ?',
      //   achievable: true,
      //   related: true,
      //   timely: '2022-11-09',
      //   date: '2022-11-20T15:31:07.056Z',
      //   status: 'Due',
      //   color: '#c753e9',
      //   tasks: [],
      //   id: '452838091537'
      // },
      // {
      //   category: 'Academic',
      //   subcategory: 'Academic Improvement',
      //   task: 'Improve Grades',
      //   title: 'Title Your Goal',
      //   support: 'Who can help you reach your goal?',
      //   celebrate: 'How will you celebrate when you reach your goal ?',
      //   specific: 'What specifically would you like to accomplish ?',
      //   measurable: 'How will you know when you have accomplished your goal ?',
      //   achievable: true,
      //   related: true,
      //   timely: '2022-11-13',
      //   date: '2022-11-20T15:32:13.536Z',
      //   status: 'Due',
      //   color: '#3d77d8',
      //   tasks: [],
      //   id: '066959720808'
      // }
    goal: {
      category: '',
      subcategory: '',
      task: '',
      title: '',
      support: '',
      celebrate: '',
      specific: '',
      measurable: '',
      achievable: true,
      related: true,
      timely: moment().add(1, 'days'),
      date: moment(),
      status: 'Due',
      //goal_id: null,
      color: '#f07a5f',
      tasks: []
    },
    editgoal: {}
  },
  reducers: {
    addNewGoal: (state) => {
      const random_number = () => Math.floor(Math.random() * 10)
      let id = ''
      for (let i = 0; i < 12; i++) {
        id += random_number()
      }
      const colors = ['#f07a5f', '#3d77d8', '#c753e9', '#009999']
      const color = colors[Math.floor(Math.random() * 4)]
      state.goal.id = id
      state.goal.color = color
      state.goals.push(state.goal)
    },
    removeGoal: (state, action) => {
      state.goals = state.goals.filter((item) => item.id !== action.payload)
    },
    updateGoal: (state, action) => {
      state.goal[action.payload.key] = action.payload.value
    },
    clearGoal: (state) => {
      state.goal = {
        category: '',
        subcategory: '',
        task: '',
        title: '',
        support: '',
        celebrate: '',
        specific: '',
        measurable: '',
        achievable: true,
        related: true,
        timely: moment().add(1, 'days'),
        date: moment(),
        status: 'Due',
        //goal_id:null,
        color: '#f07a5f',
        tasks: []
      }
    },
    addTask: (state, action) => {
      state.goal.tasks.push(action.payload)
    },
    removeTask: (state, action) => {
      state.goal.tasks = state.goal.tasks.filter((item) => item.id !== action.payload)
    },
    editNewGoal: (state, action) => {
      state.editgoal = state.goals.find((item) => item.id === action.payload)
    },
    updateEditGoal: (state, action) => {
      state.editgoal[action.payload.key] = action.payload.value
    },
    finalEditGoal: (state) => {
      if (state.editgoal.id) {
        state.goals = state.goals.filter((item) => item.id !== state.editgoal.id)
        state.goals.push(state.editgoal)
      }
    },
    clearEditGoal: (state) => {
      state.editgoal = {}
    },
    addEditTask: (state, action) => {
      state.editgoal.tasks.push(action.payload)
    },
    removeEditTask: (state, action) => {
      state.editgoal.tasks = state.editgoal.tasks.filter((item) => item.id !== action.payload)
    },
    AddGoals: (state, action) => {
      // console.log(action.payload.category)
      state.goals = [...state.goals, action.payload]
      // state.goals =  state.goals.map((ele) => (ele.id !== action.payload.id ? action.payload : ele))
      // state.goals =  state.goals.map((ele) => (ele.id !== action.payload.id ? console.log("ok") : console.log("no")))
    },
    RemoveGoals:(state, action) => {
      // console.log("REmove goal")
      state.goals = []
    }
  }
})

export const {
  editNewGoal,
  addNewGoal,
  removeGoal,
  updateGoal,
  clearGoal,
  addTask,
  removeTask,
  updateEditGoal,
  finalEditGoal,
  clearEditGoal,
  addEditTask,
  removeEditTask,
  AddGoals,
  RemoveGoals
} = slice.actions

export const selectGoals = ((state) => {
  console.log("state.goals", state)
  return (
  state.goals.goals
  )
})
export const selectGoal = (state) => state.goals.goal
export const selectEditGoal = (state) => state.goals.editgoal

export const addGoal = (goal) => (dispatch) => {
  if (
    goal.title !== '' &&
    goal.support !== '' &&
    goal.celebrate !== '' &&
    goal.specific !== '' &&
    goal.measurable !== ''
  ) {
    dispatch(addNewGoal())
    dispatch(clearGoal())
    return Promise.resolve(true)
  }
  return Promise.resolve(false)
}

export const completeEditGoal = (goal) => (dispatch) => {
  if (
    goal.title !== '' &&
    goal.support !== '' &&
    goal.celebrate !== '' &&
    goal.specific !== '' &&
    goal.measurable !== ''
  ) {
    dispatch(finalEditGoal())
    dispatch(clearEditGoal())
    return Promise.resolve(true)
  }
  return Promise.resolve(false)
}

export const deleteGoal = (id) => (dispatch) => {
  dispatch(removeGoal(id))
  dispatch(clearEditGoal())
  return Promise.resolve(true)
}

export const editGoal = (id) => (dispatch) => {
  dispatch(editNewGoal(id))
  return Promise.resolve(true)
}

export default slice.reducer