import navbar from './navbar'
import layout from './layout'
import auth from './authentication'
import dailyCheckIn from './dailyCheckIn'
import calenderReducer from '@src/views/calendar/store/reducer'
import kidsActivity from './kidsActivity'
import GoalStore from '@src/views/goals/store/GoalStore'
import goalsReducer from './tasksslice'

const rootReducer = {
  auth,
  navbar,
  layout,
  calendar: calenderReducer,
  dailyCheckIn,
  kidsActivity,
  GoalStore,
  goals: goalsReducer
}

export default rootReducer