// ** Logo
import logo from '@src/assets/images/logo/logo.gif'

const SpinnerComponent = () => {
  return (
    <div className="fallback-spinner app-loader">
      <img className="fallback-logo" width='90px' src={logo} alt="logo" />
      {/* <Spinner className="mt-5" color="primary" size="lg" /> */}
    </div>
  )
}

export default SpinnerComponent
