// ** Redux Imports
import rootReducer from './rootReducer'
import { configureStore } from '@reduxjs/toolkit'
import { combineReducers } from 'redux'
import persistReducer from 'redux-persist/es/persistReducer'
import { siteName } from '@src/constants/siteVariables'
import storage from 'redux-persist/lib/storage'
import persistStore from 'redux-persist/es/persistStore'
// import { persistReducer } from 'redux-persist'
const persistConfig = {
  key: siteName,
  storage,
  whitelist: ['auth', 'dailyCheckIn', 'kidsActivity']
}
const newRootReducer = combineReducers(rootReducer)

const persistedReducer = persistReducer(persistConfig, newRootReducer)

const store = configureStore({
  reducer: persistedReducer,
  devTools: process.env.NODE_ENV !== 'production',
  middleware: (getDefaultMiddleware) => {
    return getDefaultMiddleware({
      serializableCheck: false
    })
  }
})

const persistor = persistStore(store)

export { store, persistor }
