import moment from 'moment'

export const siteName = 'ECY-web-app'
export const reduxPersistKey = 'ECY-web-app'

export const baseUrl = 'https://empowering-production.herokuapp.com'
// export const baseUrl = 'https://empowering-staging.herokuapp.com'

export const googleMapsApiKey = 'AIzaSyBs9iJzThW-gVXM1m9RwYD328Mb34HVL4c'

export const defaultAvatar = 'https://graph.facebook.com/v3.3/4400157070047250/picture?type=normal'

export const paginationPerPage = 50
const currentDate = new Date()

export const availableRoles = {
  student: { name: 'STUDENT', id: '13' },
  teacher: { name: 'TEACHER', id: '9' },
  schoolAdmin: { name: 'SCHOOL ADMIN', id: '10' },
  superAdmin: { name: 'SUPER ADMIN', id: '12' },
  parent: { name: 'PARENT', id: '11' },
  subOrgAdmin: { name: 'SUB ORG ADMIN', id: '14' }
}

export const roles = [
  { text: 'STUDENT', value: '13' },
  { text: 'TEACHER', value: '9' },
  { text: 'PARENT', value: '11' },
  { text: 'SCHOOL ADMIN', value: '10' }
]

export const availableGrades = [
  { text: 'ELEMENTARY Grades 1-2', value: '40' },
  { text: 'TEAM EMPOWER Grade 3', value: '7' },
  { text: 'TEAM EMPOWER Grades 4-5', value: '6' },
  { text: 'Middle School', value: '5' },
  { text: 'High School', value: '4' }
]

export const availableOrgTypes = [
  { text: 'Elementary School', value: 'ELEMENTARY SCHOOL' },
  { text: 'Middle School', value: 'MIDDLE SCHOOL' },
  { text: 'High School', value: 'HIGH SCHOOL' },
  { text: 'Collage', value: 'COLLAGE' }
]

export const subscriptionExpire = [
  { text: '0 days', value: moment(currentDate).add(0, 'd').format() },
  { text: '7 days', value: moment(currentDate).add(7, 'd').format() },
  { text: '1 Month', value: moment(currentDate).add(30, 'd').format() },
  { text: '2 Months', value: moment(currentDate).add(60, 'd').format() },
  { text: '3 Months', value: moment(currentDate).add(90, 'd').format() }
]
