import _ from 'lodash'

export const getCurrentLang = () => localStorage.getItem('lng') || 'en'

export const transformResponse = (data) => {
  let lang = getCurrentLang()

  if (lang === 'en') {
    return data
  } else if (lang === 'fr') {
    lang = '_fr'
  } else if (lang === 'sp') {
    lang = '_es'
  } else if (lang === 'pt') {
    lang = '_pt'
  }

  if (_.isArray(data)) {
    return data.map((single) => transformResponse(single))
  }

  const abc = {}
  const follow = ['key_value', 'role']
  let tempData = _.mapKeys(data, (v, k) => {
    if (k.includes(lang) && !follow.find((single) => k.includes(single)) && data[k]) {
      abc[`${k.split(lang)[0]}_en`] = data[k.split(lang)[0]]
      return k.split(lang)[0]
    } else return k
  })

  tempData = { ...tempData, ...abc }

  return _.mapValues(tempData, function (val) {
    if (_.isArray(val)) {
      return val.map((single) => transformResponse(single))
    } else if (_.isObject(val)) {
      return transformResponse(val)
    } else {
      return val
    }
  })
}

export const formattedResponse = (records) => {
  return records.map((record) => ({
    ..._.mapKeys(record.fields, (v, k) => _.camelCase(k)),
    id: record.id
  }))
}
