import { createSlice } from '@reduxjs/toolkit'
import { useSelector } from 'react-redux'

const initialState = {
  activities: []
}

export const kidsActivity = createSlice({
  name: 'kidsActivity',
  initialState,
  reducers: {
    addKidActivities: (state, action) => {
      state.activities = action.payload
    }
  }
})

export const { addKidActivities } = kidsActivity.actions

export const useKidsActivity = () => {
  return useSelector((state) => state.kidsActivity.activities)
}

export default kidsActivity.reducer
